import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, WEB_ANALYTICS_PPVF_PRICES } from "@kraftheinz/common";
import { M as MyUtil } from "./MyUtil.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Maintain Pricing Assumptions", "ph-search-by": "Search by Item Code", "has-active": false, "search-by": "ItemCode", "has-filter-inactive": false, "create-button-text": "Add Price" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Prices for Country")]), _c("div", { staticClass: "country-filter mr-2" }, [_c("select-filter", { key: "Country", attrs: { "source": "countryId", "placeholder": "Country", "data-source": _vm.countries, "source-label": _vm.generateCountryLabel, "value": _vm.selectedCountry, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "CountryID");
    } } })], 1), _c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Procurement Specialist:")]), _c("div", { staticClass: "procurement-filter mr-2" }, [_c("select-filter", { key: "ProcSpecId", attrs: { "source": "procSpecId", "source-label": "procurementSpecialist", "reference": "web-analytics.common.ppvfw-procurement-specialist", "placeholder": "Procurement", "value": _vm.selectedProcurement, "default-active-first-option": "" }, on: { "change": function($event) {
      return _vm.onSelectFilterChange($event, "ProcSpecID");
    } } })], 1), _c("div", [_c("a-button", { attrs: { "icon": "close-circle" }, on: { "click": _vm.onClearFilterClick } }, [_vm._v("Clear Filter")])], 1)];
  }, proxy: true }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var record = ref.record;
    return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return _vm.onDelete(record);
    } } })], 1) : _vm._e()];
  } }]) }, [_c("url-field", { key: "PriceID", attrs: { "data-index": "priceID", "title": "Price ID", "clickable": false, "base-url": "" } }), _c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item Code" } }), _c("text-field", { key: "ItemDesc", attrs: { "data-index": "itemDesc", "title": "Item Desc" } }), _c("text-field", { key: "Currency", attrs: { "data-index": "currency", "title": "Currency" } }), _c("text-field", { key: "IntStartDate", attrs: { "data-index": "intStartDate", "title": "Start Date", "parse": _vm.parseDate } }), _c("text-field", { key: "IntEndDate", attrs: { "data-index": "intEndDate", "title": "End Date", "parse": _vm.parseDate } }), _c("text-field", { key: "Price", attrs: { "data-index": "price", "title": "Price" } })], 1);
};
var staticRenderFns$1 = [];
var Prices_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-3064521d]{min-width:160px}.procurement-filter[data-v-3064521d]{min-width:230px}\n")();
const __vue2_script$1 = {
  name: "Prices",
  inject: ["crud", "can"],
  mixins: [MyUtil],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  created() {
    this.getCountries(false);
  },
  methods: {
    parseDate(value) {
      if (!value)
        return;
      return this.$moment(`${value}`).format("YYYY/MM/DD");
    },
    onDelete(record) {
      this.$confirm({
        class: "list__modal-delete",
        icon: "delete",
        title: `Do you want to delete Price?`,
        content: `If you click Yes, you won\u2019t be able to undo this Delete operation. Are you sure you want delete Price?`,
        cancelText: "No",
        okText: "Delete",
        okType: "danger",
        onOk: this.onConfirmDelete(record)
      });
    },
    onConfirmDelete(record) {
      return () => {
        this.axios.put(`${this.apiUrl}/web-analytics/input-prices/${record.priceID}`, {
          CountryID: record.countryID,
          Currency: record.currency,
          ItemCode: record.itemCode,
          IntStartDate: record.intStartDate,
          IntEndDate: record.intEndDate,
          Price: record.price,
          UpdateType: "delete"
        }).then(() => {
          this.crud.fetchList();
          this.$notification["success"]({
            message: "Item Deleted!"
          });
        }).catch(() => {
          this.$notification["error"]({
            message: "Something went wrong, please try again!"
          });
        });
      };
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "3064521d", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Prices = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.common.ppvfw-procurement-specialist", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "web-analytics.input-prices", "api-url": _vm.apiUrl, "resource-id-name": "priceID", "create-route": "/web-analytics/prices/create", "edit-route": "/web-analytics/prices/:id", "page": _vm.page } }, [_c("prices")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    Prices
  },
  data() {
    return {
      page: WEB_ANALYTICS_PPVF_PRICES,
      Prices,
      apiUrl,
      itemsMenu: [
        {
          key: "ppv-forecast",
          title: "PPV-Forecast",
          path: ""
        },
        {
          key: "extra-costs",
          title: "Maintain Pricing Assumptions",
          path: "/web-analytics/prices"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
